.scale {
    animation: scaleIn ease 1s;
    -webkit-animation: scaleIn ease 1s;
    -moz-animation: scaleIn ease 1s;
    -o-animation: scaleIn ease 1s;
    -ms-animation: scaleIn ease 1s;
}
@keyframes scaleIn {
    0% {
        scale: 0.9;
    }
    100% {
        scale: 1;
    }
}

@-moz-keyframes scaleIn {
    0% {
        scale: 0.9;
    }
    100% {
        scale: 1;
    }
}

@-webkit-keyframes scaleIn {
    0% {
        scale: 0.9;
    }
    100% {
        scale: 1;
    }
}

@-o-keyframes scaleIn {
    0% {
        scale: 0.9;
    }
    100% {
        scale: 1;
    }
}

@-ms-keyframes scaleIn {
    0% {
        scale: 0.9;
    }
    100% {
        scale: 1;
    }
}
