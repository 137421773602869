@import "mobile.scss";
@import "fade.scss";
@import "scale.scss";

body {
    font-size: 14px;
    font-weight: 400;
    color: #666;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
}
.loader-container {
    display: flex;
    z-index: 2;
    position: relative;
    margin-top: -400px;
    height: 100vh;
    background-color: #fff;
}
.loader {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    span {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: grey;
        margin: 0 10px;
    }
}

.loader span:nth-child(1) {
    animation: 1s jump infinite ease-in-out;
}

.loader span:nth-child(2) {
    animation: 1s 0.333s jump infinite ease-in-out;
}

.loader span:nth-child(3) {
    animation: 1s 0.667s jump infinite ease-in-out;
}

@keyframes jump {
    0% {
        transform: translateY(0) scale(1);
    }
    30% {
        transform: translateY(-20px) scale(1.2);
    }
    75% {
        transform: translateY(0) scale(1);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.header {
    height: 400px;
    overflow: hidden;
    h1,
    p {
        line-height: 1.375em;
        color: #ffffff;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
        margin: 0.1em 0;
    }
}
.no-margin {
    margin: 0;
}
.v-center {
    align-content: center;
    align-self: center;
}
.highlight-card {
    position: relative;
    margin: 2rem 0 0 0;
}
.highlight-card-inner {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 1s;
    &:hover {
        opacity: 1;
    }
    h4 {
        color: white;
    }
}
.highlight-img {
    width: -webkit-fill-available;
    width: -moz-available;
    min-width: stretch;
    max-width: stretch;
    height: auto;
}
.info {
    padding: 5rem;
    p {
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
    }
}
.w-100 {
    width: 100%;
}
.contact {
    padding: 5rem;
    background-color: #afafaf;
    h3 {
        font-size: 40px;
        font-weight: 700;
        color: #3a3a3a;
    }
    a {
        width: fit-content;
        font-size: 17px;
        font-weight: 700;
        color: #3a3a3a;
        &:hover {
            color: #3f5dcf;
        }
    }
}
.socials {
    margin-top: 3rem;
    .social-link {
        border: 1px solid;
        -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
            0 1px 2px rgba(0, 0, 0, 0.065);
        -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
            0 1px 2px rgba(0, 0, 0, 0.065);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
            0 1px 2px rgba(0, 0, 0, 0.065);
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
        color: #ffffff !important;
        border-radius: 0.25em;
        padding: 1rem;
        margin: 0.2rem;
    }
    .linkedin-in {
        background: #0177b4;
        background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, #015581),
            color-stop(1, #0177b4)
        );
        background: -ms-linear-gradient(bottom, #015581, #0177b4);
        background: -moz-linear-gradient(
            center bottom,
            #015581 0%,
            #0177b4 100%
        );
        background: -o-linear-gradient(#0177b4, #015581);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0177b4', endColorstr='#015581', GradientType=0);
        border-color: #014b72 #014568 #003b59 #014568;
    }
    .github {
        background: #202021;
        background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, #070707),
            color-stop(1, #202021)
        );
        background: -ms-linear-gradient(bottom, #070707, #202021);
        background: -moz-linear-gradient(
            center bottom,
            #070707 0%,
            #202021 100%
        );
        background: -o-linear-gradient(#202021, #070707);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#202021', endColorstr='#070707', GradientType=0);
        border-color: #050505 #000000 #000000 #000000;
    }
    .facebook-f {
        background: #3a5795;
        background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, #2c4270),
            color-stop(1, #3a5795)
        );
        background: -ms-linear-gradient(bottom, #2c4270, #3a5795);
        background: -moz-linear-gradient(
            center bottom,
            #2c4270 0%,
            #3a5795 100%
        );
        background: -o-linear-gradient(#3a5795, #2c4270);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3a5795', endColorstr='#2c4270', GradientType=0);
        border-color: #273b65 #25375e #203053 #25375e;
    }
    .instagram {
        background: #3d739c;
        background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0, #2f5877),
            color-stop(1, #3d739c)
        );
        background: -ms-linear-gradient(bottom, #2f5877, #3d739c);
        background: -moz-linear-gradient(
            center bottom,
            #2f5877 0%,
            #3d739c 100%
        );
        background: -o-linear-gradient(#3d739c, #2f5877);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3d739c', endColorstr='#2f5877', GradientType=0);
        border-color: #2a506c #274a65 #23425a #274a65;
    }
    i {
        width: 1rem;
    }
}
//Timline
.timeline {
    margin-top: 5rem;
    h2 {
        font-size: 40px;
        font-weight: 700;
        color: #3a3a3a;
    }
}
.timeline-container {
    &:nth-child(1) {
        .timeline-img {
            background: #748bf7 !important;
        }
    }
}
.timeline-block {
    margin-top: 2rem;
    position: relative;
    //height: 100vh;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 4px;
        background: #efefef;
        left: 50%;
        margin-left: -2px;
        box-sizing: border-box;
    }
}
.timeline-img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 50%;
    margin-left: -15px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
        0 3px 0 4px rgba(0, 0, 0, 0.05);
    background: #ccc;
}
.timeline-wrapper {
    position: relative;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    margin-bottom: 3rem;
}
.timeline-content {
    h3 {
        color: #3a3a3a;
        font-size: 17px;
        font-weight: 700;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 0.2rem;
    }
}
footer {
    background-color: #2d2d2d;
    color: #fff;
    height: 75px;
    p {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        padding-top: 30px;
    }
}
.to-top {
    cursor: pointer;
    color: #fff;
    border: 1px solid #4f6df5;
    background: #748bf7;
    height: auto;
    right: 3px;
    margin-right: 22px;
    margin-top: auto;
    padding: 3px 6px;
    position: fixed;
    top: 85%;
    width: 45px;
    height: 45px;
    z-index: 60;
    border-radius: 50px;
    color: #fff;
    line-height: 36px;
    text-align: center;
    -webkit-box-shadow: 0 3px 23px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 23px rgba(0, 0, 0, 0.3);
}
