@media (max-width: 900px) {
    .header {
        h1 {
            font-size: 2.5rem;
        }
    }
    .info {
        margin-top: 3rem !important;
        padding: 0 !important;
        margin-bottom: 3rem !important;
    }
    .contact {
        padding: 1rem !important;
        h3 {
            font-size: 24px !important;
        }
    }
    .socials {
        margin-bottom: 2rem;
    }
    .timeline-block {
        &::before {
            left: 3rem !important;
            margin-left: 0px !important;
        }
    }
    .timeline {
        h2 {
            font-size: 24px !important;
        }
    }
    .timeline-img {
        width: 26px !important;
        height: 26px !important;
        left: 3rem !important;
        margin-left: -10px !important;
    }
    footer {
        p {
            text-align: center;
        }
    }
}
